*{margin:0px;Padding:0px;box-sizing: border-box;}



.comingSoon{
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    margin:0px;
}
.container_Com{
    padding: 7% 5%;
}
.h1_Com{
    width: 50%;text-align: left;font-weight: bold;color: white;filter: drop-shadow(2px 4px 6px black);
    font-size: 60px;padding: 7% 5%;
}
@media only screen and (min-width:650px) and (max-width:992px){
    .h1_Com {
        
        font-size: 35px;
        padding: 5%;
        width:75%;
    }
}
@media only screen and (max-width:650px){
    .h1_Com {
        
        font-size: 30px;
       width:100%;
    }
}

@media only screen and (min-width:992px) and (max-width:1220px){
    .h1_Com {font-size: 45px;}
}